import vue from 'vue'

export default {
  state: {
    map: {},
    fields: {
      events: null,
      orders: null,
      cameras: null,
      detectors: null,
      apvgc: null
    },
    city: '',
    additionalLayers: {
      cameras: false,
      detectors: false,
      apvgc: false,
      heatmap: false
    },
    objectCard: null,
    cameraCard: null,
    services: {
      statuses: null,
      eventTypes: null,
      executors: null,
      statusesCamera: null
    }
  },
  getters: {
    getEmergencyMapConfig: state => state.map,
    getEmergencySelectedCity: state => state.city,
    getEmergencyFields: state => key => state.fields[key],
    getEmergencyColorById: state => id =>
      state.services.statuses?.[id]?.color || '#ffffff',
    getEmergencyCameraColorById: state => id =>
      state.services.statusesCamera?.[id]?.color || '#ffffff'
  },
  mutations: {
    EMERGENCY_SET(state, [field, value]) {
      if (field.includes('.')) {
        const [parent, child] = field.split('.')
        vue.set(state[parent], `${child}`, value)
      } else {
        vue.set(state, `${field}`, value)
      }
    }
  }
}
