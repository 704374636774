import vue from 'vue'

export default {
  state: {
    map: {},
    fields: {
      parkings: null,
      parkomats: null,
      terminals: null,
      tariffs: null
    },
    city: '',
    heatmap: false,
    parkingMeters: false,
    parkingTerminals: false,
    objectCard: null,
    objectEditor: null,
    tariff: null
  },
  getters: {
    getParkingMapConfig: state => state.map,
    getParkingFields: state => key => state.fields[key],
    getParkingSelectedCity: state => state.city
  },
  mutations: {
    PARKING_SET(state, [field, value]) {
      if (field.includes('.')) {
        const [parent, child] = field.split('.')
        vue.set(state[parent], `${child}`, value)
      } else {
        vue.set(state, `${field}`, value)
      }
    }
  }
}
