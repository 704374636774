<template>
  <div
    :class="[
      'r-color-item',
      { active, wide: mode === 'wide', transparent: mode === 'add' }
    ]"
    @click="$emit(mode || 'click')"
  >
    <r-icon
      v-if="mode === 'add'"
      name="add-plus"
    />
    <div
      v-else
      class="r-color-item__canvas"
    >
      <div
        :style="{
          backgroundColor: color,
          opacity: opacity
        }"
        class="r-color-item__color"
      >
        <r-icon
          v-if="mode === 'remove'"
          name="close-delete"
          class="r-color-item__close-icon"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: 'transparent'
    },
    opacity: {
      type: Number,
      default: 1
    },
    mode: {
      type: String,
      default: ''
    },
    active: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.r-color-item {
  height: 36px;
  width: 36px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.25rem;
  background-color: $bg-containers-white;
  border-radius: $border-radius;
  cursor: pointer;
  transition: ease 0.15s;

  &.active {
    border: 1px solid $field-active-border;
  }

  &.wide {
    width: 100%;
    cursor: auto;

    .r-color-item__canvas {
      background: url('@/assets/images/helpers/canvas.jpg') center / cover;
    }
  }

  &.transparent {
    background-color: transparent;

    &:hover {
      background-color: $bg-containers-white;

      .r-icon__svg {
        transform: scale(1.1);
      }
    }
  }

  &__canvas {
    height: 100%;
    width: 100%;
    overflow: hidden;
    border-radius: $border-radius;
  }

  &__color {
    height: 100%;
    width: 100%;
    border-radius: $border-radius;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__close-icon:hover {
    transform: scale(1.1) !important;
  }
}
</style>
