<template>
  <label :class="['r-switch', { disabled, between, rtl }]">
    <input
      type="checkbox"
      class="r-switch__input"
      :disabled="disabled"
      :checked="elChecked"
      @change="$emit('change', $event.target.checked)"
    />
    <div class="r-switch__wrapper">
      <span class="r-switch__switcher" />
      <r-text
        v-if="label"
        class="r-switch__switcher-title"
      >
        {{ label }}
      </r-text>
      <!-- TODO: make active/inactive label -->

      <!-- expml: | _off_ (O  ) on | -->
      <!-- expml: | inactive (  O) _active_ | -->
    </div>
  </label>
</template>

<script>
export default {
  model: {
    prop: 'checked',
    event: 'change'
  },
  props: {
    checked: {
      type: Boolean
    },
    disabled: {
      type: Boolean,
      default: false
    },
    between: {
      type: Boolean,
      default: false
    },
    rtl: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ''
    }
  },
  data() {
    return { elChecked: !!this.checked }
  },
  watch: {
    checked(v) {
      this.elChecked = v
    }
  }
}
</script>

<style lang="scss">
.r-switch {
  display: flex;
  padding: 0.25rem 0.25rem 0.25rem 0.5rem;
  align-items: center;

  &__input {
    height: 0;
    width: 0;
    visibility: hidden;

    &:checked + .r-switch__wrapper > .r-switch__switcher {
      background: $accent-primary;

      &:after {
        left: calc(100% - 18px);
      }
    }
  }

  &__wrapper {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    cursor: pointer;
    width: 100%;
  }

  &.rtl {
    justify-content: stretch;
    direction: rtl;
  }

  &.between {
    padding: 0.25rem 0.25rem 0.25rem 0rem;
    width: 100%;

    .r-switch__wrapper {
      justify-content: space-between;
    }
  }

  &__switcher {
    width: 38px;
    height: 16px;
    background-color: $button-secondary-bg;
    display: block;
    border-radius: 0.5rem;
    position: relative;
    transition: background-color 0.15s ease-in;

    &:after {
      content: '';
      position: absolute;
      top: 50%;
      filter: drop-shadow(2px 2px 5px $modal-overlay);
      transform: translateY(-50%);
      left: -2px;
      width: 20px;
      height: 20px;
      background: $white;
      border-radius: 50%;
      transition: left 0.15s ease-in;
      transform-origin: 50% 50%;
    }
  }

  &.disabled {
    opacity: 0.4;
  }
}
</style>
