<template>
  <ul
    ref="list"
    :class="['select-menu', `${multiple ? 'multiple' : ''}`, mode]"
    :style="{
      top: `${top}px`,
      bottom: `${bottom}px`,
      left: `${left}px`,
      width: `${width}px`
    }"
  >
    <template v-if="filteredOptions.length">
      <r-select-option
        v-if="multiple && !hasNoTextData"
        id="select_all"
        :class="[
          'select-menu__menu-item',
          `${focusedElementId === 'select_all' ? 'focus' : ''}`
        ]"
        @click="$emit('select-all')"
      >
        <checkbox-status :state="selectedStatus" />
        <r-text>
          {{ $t('select_all') }}
        </r-text>
      </r-select-option>
      <r-select-option
        v-for="option in filteredOptions"
        :id="option.id"
        :key="option.id"
        :disabled="option.disabled"
        :title="option.title || option.name"
        :html="option.html"
        @click.stop="itemClickHandler(option.id, $event)"
      />
    </template>
    <div
      v-else
      class="select-menu__no-data"
    >
      <r-text>
        {{ $t('no-data') }}
      </r-text>
    </div>
  </ul>
</template>

<script>
export default {
  components: {
    checkboxStatus: () => import('./checkbox-status')
  },
  props: {
    top: {
      type: Number,
      default: null
    },
    bottom: {
      type: Number,
      default: null
    },
    left: {
      type: Number,
      default: null
    },
    width: {
      type: Number,
      default: null
    },
    filteredOptions: {
      type: Array,
      default: () => []
    },
    multiple: {
      type: Boolean,
      default: false
    },
    active: {
      type: [Array, String, Number],
      default: null
    },
    mode: {
      // ellipsis, tooltip, wrap
      type: String,
      default: 'ellipsis'
    },
    hasNoTextData: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      focusedElementId: null
    }
  },
  computed: {
    selectedStatus() {
      if (this.active.length === 0) return 'unchecked'

      const allChecked = this.filteredOptions.every(
        ({ id, disabled }) => disabled || this.active.includes(id)
      )

      return allChecked ? 'checked' : 'indeterminate'
    }
  },
  mounted() {
    document.addEventListener('keydown', this.keyHandler)
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.keyHandler)
  },
  methods: {
    keyHandler(evt) {
      const keys = ['ArrowDown', 'ArrowUp', 'Enter', 'Escape', 'Tab']
      if (!keys.includes(evt.code)) return
      evt.preventDefault()

      const items = Array.from(this.$children)
      const focusedElement = items.find(
        item => item.id === this.focusedElementId
      )

      switch (evt.code) {
        case 'ArrowDown':
          this.arrowDownHandler(items, focusedElement)
          break
        case 'ArrowUp':
          this.arrowUpHandler(items, focusedElement)
          break
        case 'Enter':
          this.enterHandler(focusedElement)
          break
        default:
          this.closeHandler()
          break
      }
    },
    closeHandler() {
      this.$emit('item-click')
    },
    arrowDownHandler(items, focusedElement) {
      if (focusedElement) {
        let index = items.indexOf(focusedElement) + 1
        if (items.length === index) {
          index = 0
        }
        this.focusedElementId = items[index]?.id
      } else if (!this.multiple) {
        this.focusedElementId =
          items[items.findIndex(i => i.isSelected) + 1]?.id
      } else {
        this.focusedElementId = items[0].id
      }
    },
    arrowUpHandler(items, focusedElement) {
      if (focusedElement) {
        let index = items.indexOf(focusedElement) - 1

        if (index === -1) {
          index = items.length - 1
        }
        this.focusedElementId = items[index]?.id
      } else if (!this.multiple) {
        this.focusedElementId =
          items[items.findIndex(i => i.isSelected) - 1]?.id
      } else {
        this.focusedElementId = items[0].id
      }
    },
    enterHandler(focusedElement) {
      if (!focusedElement) return
      if (focusedElement.id === 'select_all') {
        this.$emit('select-all')
        return
      }
      const id = focusedElement.id
      this.itemClickHandler(id)
    },
    itemClickHandler(id, evt) {
      if (evt) evt.preventDefault()
      const option = this.getOptionById(id)
      const isId = id => !!id || Number.isInteger(parseInt(id))
      if (!isId(id) || id === 'select_all' || option?.disabled) return
      this.$emit('item-click', id, evt)
    },
    getOptionById(id) {
      return this.filteredOptions.find(item => item.id === id)
    }
  }
}
</script>

<style lang="scss" scoped>
.select-menu {
  position: fixed;
  background: $field-droplist-bg;
  z-index: 3100;
  padding: 8px 0;
  border-radius: 4px;
  filter: drop-shadow(2px 2px 5px $modal-overlay);
  max-height: 250px;
  overflow-y: auto;

  &.multiple {
    .select-menu__menu-item {
      padding-left: 12px;

      &.active {
        background: unset;
      }

      &:hover {
        background: $field-hover-bg;
      }
    }
  }

  &__menu-item {
    padding: 6px 8px 10px;
    cursor: pointer;
    display: grid;
    grid-auto-flow: column;
    align-items: center;
    grid-gap: 6px;
    justify-content: start;

    &.focus {
      background: $field-hover-bg !important;
    }

    &:hover {
      background: $field-hover-bg;
    }

    &.active {
      background: $accent-primary-1;
    }

    &.disabled {
      opacity: 0.4;
      cursor: not-allowed;
    }

    .r-text {
      white-space: nowrap;
    }

    .r-tooltip__wrapper,
    .select-menu__item-wrapper {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &__no-data {
    padding: 8px;
  }
}
</style>
