// MAIN_TITLE
import en from './en.json'
import ru from './ru.json'
// Modules
import gis from '@/modules/gis/config/locale'
import mrir from '@/modules/mrir/config/locale'
import user from '@/modules/user/config/locale'
import ksodd from '@/modules/ksodd/config/locale'
import asudd from '@/modules/asudd/config/locale'
import its from '@/modules/its-control/config/locale'
import parking from '@/modules/parking/config/locale'
import mciud from '@/modules/mciud/config/locale.json'
import explorer from '@/modules/explorer/config/locale'
import modeling from '@/modules/modeling/config/locale'
import licenses from '@/modules/licenses/config/locale'
import roadWorks from '@/modules/road-works/config/locale'
import emergency from '@/modules/emergency/config/locale'
import digitalTwin from '@/modules/digital-twin/config/locale'
import monitoringPt from '@/modules/monitoring-pt/config/locale'
import pdd from '@/modules/pdd/config/locale'

import drawPanel from '@/components/common/draw-panel/config/locale.json'

export default {
  ru: Object.assign(
    ru,
    its.ru,
    gis.ru,
    mrir.ru,
    user.ru,
    asudd.ru,
    parking.ru,
    ksodd.ru,
    mciud.ru,
    licenses.ru,
    modeling.ru,
    explorer.ru,
    roadWorks.ru,
    emergency.ru,
    digitalTwin.ru,
    monitoringPt.ru,
    drawPanel.ru,
    pdd.ru
  ),
  en: Object.assign(
    en,
    its.en,
    gis.en,
    mrir.en,
    user.en,
    asudd.en,
    parking.en,
    ksodd.en,
    mciud.en,
    licenses.en,
    modeling.en,
    explorer.en,
    roadWorks.en,
    emergency.en,
    digitalTwin.en,
    monitoringPt.en,
    drawPanel.en,
    pdd.en
  )
}
