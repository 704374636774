import Vue from 'vue'
import locale from 'element-ui/lib/locale'
import lang from '@/config/element_local'
import { DatePicker, TimePicker, Upload, Slider, Pagination } from 'element-ui'

locale.use(lang)

const option = { size: 'small' }

Vue.use(DatePicker, option)
Vue.use(TimePicker, option)
Vue.use(Upload, option)
Vue.use(Slider, option)
Vue.use(Pagination, option)
