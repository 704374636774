const showConsoles = false // toggle here

export const eventNames = {
  // Tools
  MAP_UPDATE_LAYER_STYLE: 'map-update-layer-style',
  MAP_LINE_LENGTH_UPDATE: 'map-line-length-update',
  MAP_AREA_SQUARE_UPDATE: 'map-area-square-update',
  MAP_COORDS_UPDATE: 'map-coords-update',
  MAP_RESIZE: 'map-resize',
  ADD_RADIUS_CENTER_POINT: 'add-radius-center-point',

  // Layers
  MAP_CHANGE_PROPERTY: 'map-change-property',
  MAP_UPDATE_FILTER: 'map-update-filter',
  MAP_LAYER_UPDATED: 'map-layer-updated',
  MAP_DATA_UPDATED: 'map-data-updated',
  MAP_UPDATE_LAYER: 'map-update-layer',
  MAP_SET_FILTER: 'map-set-filter',

  // Common events
  MAP_NO_LAYER_DATA: 'map-no-layer-data',
  CHANGE_CARD_INFO: 'change-card-info',
  MAP_GEOM_CREATED: 'map-geom-created',
  MAP_GEOM_CHANGED: 'map-geom-changed',
  MAP_OBJECT_CLICK: 'map-object-click',
  MAP_CLOSE_OBJECT: 'map-close-object',
  MAP_OBJECT_LIST: 'map-object-list',
  CLEAR_ALL_DRAW: 'clear-all-draw',
  MAP_SHOW_POPUP: 'map-show-popup',
  MAP_FLY_TO_GEOM: 'map-fly-to-geom',

  // System
  TOGGLE_DRAW_PANEL: 'toggle-draw-panel',
  MAP_DATA_LOADING: 'map-data-loading',
  CHANGE_BASELAYER: 'change-baselayer',
  TOGGLE_EXPLORER: 'toggle-explorer',
  UPDATE_PROFILE: 'update-profile',
  CHANGE_LOCALE: 'change-locale',
  LICENSE_ERROR: 'license-error',
  CHANGE_THEME: 'change-theme',
  USER_LOADED: 'user-loaded',
  TREE_CLICK: 'tree-click',
  WS_MESSAGE: 'ws-message',
  WS_INITED: 'ws-inited',

  // GIS
  GIS_EXTERMINATE_LAYER: 'gis-exterminate-layer',
  GIS_TABLES_UPDATED: 'gis-tables-updated',

  // KSODD
  KSODD_UPDATE_TIMELINE_FILTER: 'ksodd-update-timeline-filter',
  KSODD_TIMELINE_UPDATED: 'ksodd-timeline-updated',

  // ITS
  ITS_BUILD_TRACK: 'its-build-track',

  // ASUDD
  ASUDD_SET_SELECTED_NODE: 'asudd-set-selected-node',

  // MODELING
  LOAD_FULL_DATASET: 'load-full-dataset',

  // DIGITAL TWIN
  RENDER_RELATED_LAYER: 'render-related-layer',
  ACCEPT_POLYGON_GEOM: 'accept-polygon-geom',

  // ROAD_WORKS
  RW_VEHICLE_TOGGLE_TRACK: 'rw-vehicle-toggle-track'
}

class EventBus {
  #events = {}

  once(event, callback) {
    if (!event) console.error("event doesn't exist")
    if (showConsoles) console.log('once', event)

    const wrapperFn = (...params) => {
      callback(...params)
      this.off(event, wrapperFn)
    }

    this.#addListener(event, wrapperFn, true)
  }

  on(event, callback) {
    if (!event) console.error("event doesn't exist")
    if (showConsoles) console.log('on', event)
    this.#addListener(event, callback, false)
  }

  off(event, callback) {
    this.#events[event] = this.#events[event].filter(
      event => event.callback !== callback
    )
  }

  emit(event, ...params) {
    if (!event) console.error("event doesn't exist")
    if (showConsoles) console.log('emit', event, ...params)
    if (!this.#events[event]) {
      return
    }

    this.#events[event].forEach(({ callback }) => {
      callback(...params)
    })
  }

  #addListener(event, callback, once) {
    if (!this.#events[event]) {
      this.#events[event] = []
    }

    this.#events[event].push({
      callback,
      once
    })
  }
}

export default new EventBus()
