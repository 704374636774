<template>
  <div
    :class="[
      'r-select',
      `${disabled ? 'disabled' : ''}`,
      `${dropdownStyle ? 'dropdown-style' : ''}`,
      `${showAllSelectedItems ? 'show-all' : ''}`,
      `${isMenuOpened ? 'opened' : ''}`,
      `${error ? 'error' : ''}`
    ]"
  >
    <r-text
      v-if="label"
      color-type="subhead"
    >
      {{ label }}
    </r-text>
    <div
      ref="wrapper"
      class="r-select__input-wrapper"
      tabindex="0"
      :class="{ angular }"
      :style="{ maxWidth: maxWidth, width: `${width}px` }"
      @focus="inputClickHandler"
      @blur="closeMenu"
      @mouseenter="isClearButtonShowing = true"
      @mouseleave="isClearButtonShowing = false"
    >
      <div
        v-if="!hasNoTextData"
        class="r-select__selected-items"
      >
        <div
          v-for="(id, i) in showedItems"
          :key="i"
          class="r-select__selected-item"
          @click="deleteSelectedItem(id)"
        >
          <r-text :size="12">
            {{ getOptionTitleById(id) }}
          </r-text>
        </div>
        <div
          v-if="multiple && active.length > 2 && !showAllSelectedItems"
          class="r-select__selected-item r-select__selected-item--more"
        >
          <r-text
            :size="12"
            color-type="primary"
          >
            {{ `+${active.length - 2}` }}
          </r-text>
        </div>
        <input
          v-model="text"
          :title="text"
          tabindex="-1"
          class="r-select__input"
          type="text"
          :disabled="!filterable || disabled"
          :placeholder="inputPlaceholder"
          @focus="inputClickHandler"
          @input="$emit('input', active, $event)"
          @blur="closeMenu"
        />
      </div>
      <render-option
        v-else-if="selectedOption"
        :dom="selectedOption.html[0].componentOptions.children"
      />
      <r-button
        v-if="isClearButton"
        simple
        class="r-select__clear-button"
        mini
        icon="clear-input"
        @click="clearButtonClickHandler"
      />
      <r-button
        v-else
        tabindex="-1"
        class="r-select__dropdown-icon"
        :icon="{ name: dropdownStyle ? '' : 'chevron-down', size: 16 }"
        :dropdown="dropdownStyle"
        mini
        simple
        :disabled="disabled"
        :color-type="dropdownStyle ? 'primary' : ''"
        @click.native.stop="dropdownIconClickHandler"
      />
    </div>

    <div v-show="false">
      <slot />
    </div>

    <portal to="main-portal">
      <transition name="unroll">
        <select-menu
          v-if="isMenuOpened"
          ref="menu"
          :top="menuTop"
          :bottom="menuBottom"
          :left="menuLeft"
          :width="menuWidth"
          :filtered-options="filteredOptions"
          :multiple="multiple"
          :active="active"
          :mode="mode"
          :has-no-text-data="hasNoTextData"
          @item-click="itemClickHandler"
          @select-all="selectAll"
        />
      </transition>
    </portal>
  </div>
</template>

<script>
import SelectMenu from './r-select/components/select-menu'
import RenderOption from '@/components/r-ui/r-select/components/render-option'

export default {
  components: { RenderOption, SelectMenu },
  model: {
    prop: 'selected',
    event: 'change'
  },
  props: {
    options: {
      type: Array,
      default: () => []
    },
    placeholder: {
      type: String,
      default: 'Выберите пункт'
    },
    selected: {
      type: [String, Array, Number],
      default: ''
    },
    filterable: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    },
    angular: {
      type: Boolean,
      default: false
    },
    dropdownStyle: {
      type: Boolean,
      default: false
    },
    showAllSelectedItems: {
      type: Boolean,
      default: false
    },
    maxWidth: {
      type: String,
      default: '100%'
    },
    label: {
      type: String,
      default: ''
    },
    width: {
      type: Number,
      default: null
    },
    mode: {
      // ellipsis, tooltip, wrap
      type: String,
      default: 'ellipsis'
    }
  },
  data() {
    const selected = this.options.find(item => item.id === this.selected)
    return {
      filterInputText: this.multiple ? '' : selected?.title || selected?.name,
      isMenuOpened: false,
      active: this.selected,
      isClearButtonShowing: false,
      menuTop: null,
      menuBottom: null,
      menuLeft: null,
      menuWidth: null,
      slotOptions: null,
      hasNoTextData: false
    }
  },
  computed: {
    text: {
      set(value) {
        this.filterInputText = value
      },
      get() {
        return this.filterInputText
      }
    },
    opt() {
      return this.$slots.default ? this.slotOptions : this.options
    },
    filteredOptions() {
      if (!this.filterable || !this.text || this.hasNoTextData) return this.opt
      const query = this.text.toLowerCase()
      return this.opt.filter(item => {
        const name = item.title || item.name
        return name.toLowerCase().includes(query)
      })
    },
    inputPlaceholder() {
      if (this.multiple) {
        return this.active.length ? '' : this.placeholder
      } else {
        return this.placeholder
      }
    },
    isClearButton() {
      if (this.disabled) return false
      if (this.clearable && this.isMenuOpened && this.text) return true
      return (
        this.clearable &&
        (this.multiple ? this.active[0] : this.active || this.text) &&
        this.isClearButtonShowing
      )
    },
    showedItems() {
      if (!this.multiple) return []
      return this.showAllSelectedItems ? this.active : this.active.slice(0, 2)
    },
    selectedOption() {
      if (this.multiple) {
        return this.opt?.find(item => {
          return item.id === this.selected[0]
        })
      } else {
        return this.opt?.find(item => item.id === this.selected)
      }
    }
  },
  watch: {
    options() {
      if (!this.multiple) {
        const option = this.opt.find(item => item.id === this.selected)
        this.text = option?.title || option?.name
      }
    },
    selected(value) {
      this.active = value

      if (!this.multiple) {
        const option = this.opt.find(item => item.id === value)
        this.text = option?.title || option?.name
      }
    }
  },
  created() {
    if (!!this.multiple !== !!Array.isArray(this.selected)) {
      throw new Error(
        `Wrong type of 'selected' prop. Correct type: ${
          this.multiple ? 'array' : 'number/string'
        }`
      )
    }
  },
  mounted() {
    if (this.$slots.default) {
      this.hasNoTextData = this.$slots.default.some(child => {
        return (
          child.componentOptions.children.length > 1 ||
          (!child.elm.innerText &&
            !child.child.componentOptions.children[0].classList.contains(
              'r-text'
            ))
        )
      })
      this.slotOptions = this.$slots.default.map(child => {
        const title = this.hasNoTextData
          ? null
          : child.componentOptions.children[0].text ||
            child.componentOptions.children[0].componentOptions?.children?.[0]
              .text

        return {
          id: child.componentOptions.propsData.id,
          disabled: child.componentOptions.propsData.disabled,
          title: title ? title.trim() : null,
          html: this.hasNoTextData ? [child] : null
        }
      })
      const value = this.opt.find(item => item.id === this.active)
      this.text = value?.title || value?.name
    }

    this.updateMenuCoords()
    document.addEventListener('scroll', this.closeMenu, true)
    document.addEventListener(
      'mouseup',
      this.closeMenu,
      { passive: true },
      true
    )
  },
  beforeDestroy() {
    document.removeEventListener('mouseup', this.closeMenu)
    document.removeEventListener('scroll', this.closeMenu)
  },
  methods: {
    inputClickHandler(e) {
      if (this.disabled) return
      this.updateMenuCoords()
      if (e.target.closest('.r-select__clear-button')) {
        this.clearButtonClickHandler()
      } else {
        this.isMenuOpened = this.filterable ? true : !this.filterable
      }

      if (!this.multiple && this.filterable) {
        this.text = null
      }
    },
    itemClickHandler(id) {
      if (!id && id !== 0) {
        this.isMenuOpened = false
        if (this.$refs.wrapper) this.$refs.wrapper.blur()
        return
      }

      const item = this.opt.find(item => item.id === id)

      this.updateMenuCoords()
      if (!this.multiple || this.hasNoTextData) {
        if (
          item.id === this.active ||
          (this.hasNoTextData && this.multiple && this.active.includes(item.id))
        ) {
          // no need to change value
          this.isMenuOpened = false
          this.$nextTick(() => this.updateMenuCoords())
          return
        }
        this.active = this.multiple ? [item.id] : item.id
        this.text = item.title || item.name
        this.isMenuOpened = false
      } else {
        this.$refs.wrapper.focus()
        const index = this.active.indexOf(item.id)
        if (index < 0) {
          this.active.push(item.id)
        } else {
          this.active = this.active.filter(el => el !== item.id)
        }
      }
      this.$nextTick(() => this.updateMenuCoords())
      this.$emit('change', this.active)
    },
    clearButtonClickHandler() {
      if (this.disabled) return
      this.text = ''
      if (!this.multiple) {
        this.active = null
      } else {
        this.active = []
      }
      this.$nextTick(() => {
        this.updateMenuCoords()
      })
      this.$emit('change', this.active)
    },
    dropdownIconClickHandler() {
      if (this.disabled) return
      this.updateMenuCoords()
      this.isMenuOpened = !this.isMenuOpened
      if (!this.multiple && this.filterable) {
        const value = this.opt.find(item => item.id === this.active)

        this.text = this.isMenuOpened ? null : value?.title || value?.name
      }
    },
    closeMenu(e) {
      // called on an input that has lost focus, or when scrolling, or click outside

      // for @blur. Checks that new focused element exists and isn't inside menu
      const focusedElementIsInsideMenu =
        e.relatedTarget && !e.relatedTarget.closest('.select-menu')

      // for @mouseup and scroll. Checks that click was inside select
      const clickIsInsideSelect =
        !e.target.closest('.select-menu__menu-item') &&
        !e.target.closest('.r-select__input-wrapper') &&
        !e.target.closest('.r-select__dropdown-icon') &&
        !e.target.closest('.select-menu')
      if (focusedElementIsInsideMenu || clickIsInsideSelect) {
        if (!this.multiple) {
          const value = this.opt?.find(item => item.id === this.active)
          this.text = value?.title || value?.name
        } else {
          this.text = null
        }
        this.isMenuOpened = false
        if (this.$refs.wrapper) this.$refs.wrapper.blur()
      }
    },
    updateMenuCoords() {
      if (!this.$refs.wrapper) return
      const rect = this.$refs.wrapper.getBoundingClientRect()
      const windowHeight = document.documentElement.clientHeight
      const menuOnTop = rect.bottom > windowHeight - 250
      this.minWidth = rect.width

      if (menuOnTop) {
        this.menuBottom = windowHeight - rect.top + 8
        this.menuTop = null
      } else {
        this.menuTop = rect.bottom + 4
        this.menuBottom = null
      }

      this.menuLeft = rect.left
      this.menuWidth = rect.width
    },
    getOptionTitleById(id) {
      const option = this.opt?.find(item => item.id === id)
      return option?.title || option?.name
    },
    deleteSelectedItem(id) {
      if (this.disabled) return
      if (this.opt.find(item => item.id === id).disabled) return
      this.active = this.active.filter(item => item !== id)
      this.$emit('change', this.active)
    },
    selectAll() {
      const allEnabledOptionsSelected = this.opt
        .filter(option => !option.disabled)
        .map(item => item.id)
        .every(option => this.active.includes(option))

      const disabledSelectedOptionsIds = this.opt
        .filter(option => option.disabled)
        .map(disabledOption => disabledOption.id)
        .filter(id => this.active.includes(id))

      if (allEnabledOptionsSelected) {
        this.active = disabledSelectedOptionsIds
      } else {
        this.opt
          .filter(item => !item.disabled)
          .forEach(option => {
            if (this.active.indexOf(option.id) === -1)
              this.active.push(option.id)
          })
      }

      this.$emit('change', this.active)
      this.$nextTick(() => this.updateMenuCoords())
    }
  }
}
</script>

<style lang="scss" scoped>
.r-select {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;

  &.dropdown-style {
    .r-select__input-wrapper {
      background: $accent-primary-1;
      border: none;
    }

    .r-select__input {
      font-weight: 600;
      color: $button-primary-bg;
    }
  }

  &.show-all {
    .r-select__selected-item {
      max-width: 70px;
    }

    .r-select__selected-items {
      flex-wrap: wrap;
    }
  }

  &.disabled {
    .r-select__input {
      opacity: 0.4;
      cursor: not-allowed;
    }

    .r-select__dropdown-icon,
    .r-select__selected-item {
      opacity: 0.4;
    }

    .r-select__input-wrapper {
      cursor: not-allowed;
    }
  }

  &.opened {
    .r-select__dropdown-icon {
      transform: scale(1, -1);
    }

    .r-select__input-wrapper {
      border-color: $field-active-border;
    }
  }

  &.error {
    .r-select__input-wrapper {
      border-color: $accent-danger;
    }
  }

  &__input-wrapper {
    flex: 1;
    display: flex;
    gap: 4px;
    position: relative;
    background: $field-bg;
    cursor: pointer;
    align-items: center;
    padding: 0.25rem 2.5rem 0.25rem 0.5rem;
    border-radius: $border-radius;
    border: 1px solid $field-border;
    min-height: 36px;
    transition: border 0.25s ease;

    &.angular {
      border-radius: 0;
    }
  }

  &__input {
    background: transparent;
    border: none;
    color: $text-primary;
    font-size: 14px;
    cursor: pointer;
    min-width: 5px;
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__selected-items {
    display: flex;
    gap: 4px;
    align-items: center;
    width: 100%;
    overflow: hidden;
  }

  &__selected-item {
    flex-shrink: 0;
    border: 1px solid $dividers-high-contrast;
    border-radius: $border-radius;
    padding: 0 4px;
    max-width: 70px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 16px;
  }

  &__clear-button {
    position: absolute;
    right: 8px;
    top: calc(50% - 14px);
  }

  &__dropdown-icon {
    position: absolute;
    right: 0.5rem;
    top: calc(50% - 14px);
    transform: scale(1);
  }

  &__no-text-data-wrapper {
    display: flex;
    align-items: center;
  }
}

.unroll-enter-active,
.unroll-leave-active {
  transition: all 0.15s ease;
  overflow: hidden;
  display: block;
  max-height: 250px;
  opacity: 1;
}

.unroll-enter,
.unroll-leave-to {
  max-height: 0;
  opacity: 0.5;
}
</style>
