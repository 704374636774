<template>
  <div
    class="r-input-number"
    :class="{ mini, disabled }"
  >
    <r-text
      v-if="label"
      color-type="subhead"
    >
      {{ label }}
    </r-text>
    <div class="r-input-number__body">
      <r-button
        v-if="!controls"
        class="r-input-number__left"
        :mini="mini"
        icon="minus"
        :disabled="value === min || disabled"
        @click="deduct"
      />
      <r-button
        v-if="!controls"
        class="r-input-number__right"
        icon="add-plus"
        :mini="mini"
        :disabled="value === max || disabled"
        @click="add"
      />
      <div
        v-if="controls"
        class="r-input-number__controls"
        :class="{ right: controls === 'right' }"
      >
        <r-button
          class="r-input-number__control"
          :class="controlButtonClass"
          tiny-icon
          :icon="{ name: 'chevron-down', rotate: 180 }"
          mini
          :disabled="value === max || disabled"
          @click="add"
        />
        <r-button
          class="r-input-number__control"
          :class="controlButtonClass"
          icon="chevron-down"
          tiny-icon
          mini
          :disabled="value === min || disabled"
          @click="deduct"
        />
      </div>
      <input
        ref="inputNumber"
        :class="['r-input-number__input', { error }]"
        :placeholder="placeholder"
        :value="value"
        :min="min"
        :max="max"
        :step="step"
        :autocomplete="autocomplete"
        :disabled="disabled"
        type="number"
        @input="input"
        @keyup.enter="enter"
        @keypress.enter="onEnterPress"
        @focus="$emit('focus')"
        @blur="$emit('blur')"
        @click="click"
      />
      <r-text
        v-if="measure"
        color-type="secondary"
        class="r-input-number__measure"
      >
        {{ measure }}
      </r-text>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    onEnterPress: {
      type: Function,
      default: () => {}
    },
    value: {
      type: [String, Number],
      default: ' '
    },
    placeholder: {
      type: [String, Number],
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    },
    autocomplete: {
      type: String,
      default: 'on'
    },
    mini: {
      type: Boolean,
      default: false
    },
    step: {
      type: Number,
      default: 1
    },
    max: {
      type: Number,
      default: undefined
    },
    min: {
      type: Number,
      default: undefined
    },
    controls: {
      // left/right
      type: String,
      default: ''
    },
    measure: {
      type: String,
      default: ''
    }
  },
  computed: {
    controlButtonClass() {
      const { controls } = this
      return [{ left: controls === 'left' }, { right: controls === 'right' }]
    }
  },
  methods: {
    enter($event) {
      this.$emit('enter', $event)
    },
    input() {
      if (this.$refs.inputNumber.value > this.max) {
        this.$emit('input', this.max)
        this.$refs.inputNumber.value = this.max
      } else if (this.$refs.inputNumber.value < this.min) {
        this.$emit('input', this.min)
        this.$refs.inputNumber.value = this.min
      } else if (this.$refs.inputNumber.value === '') {
        this.$emit('input', this.$refs.inputNumber.value)
      } else {
        this.$emit('input', Number(this.$refs.inputNumber.value))
      }
    },
    click() {
      this.$emit('click', Number(this.$refs.inputNumber.value))
    },
    getStyle() {
      const styles = {}
      styles.resize = this.resize
      return styles
    },
    deduct() {
      this.$refs.inputNumber.stepDown(this.step)
      this.input()
      this.$emit('click', Number(this.$refs.inputNumber.value))
    },
    add() {
      this.$refs.inputNumber.stepUp(this.step)
      this.input()
      this.$emit('click', Number(this.$refs.inputNumber.value))
    }
  }
}
</script>

<style lang="scss" scoped>
.r-input-number {
  flex: 1;
  display: grid;
  grid-gap: 0.25rem;
  min-width: 100px;

  &__measure {
    min-height: 100%;
    display: flex;
    align-items: center;
    position: absolute;
    right: 12px;
  }

  &__left,
  &__right {
    border-radius: 0;
    position: absolute;
    width: 1.75rem !important;
    padding: 4px;
    border: none;
    height: 100%;
    cursor: pointer;
  }

  &__left {
    left: 0;
  }

  &__right {
    right: 0;
  }

  &__body {
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
    border-radius: $border-radius;
    overflow: hidden;
  }

  &__input {
    height: 36px;
    border-radius: $border-radius;
    padding: 0 1.75rem;
    transition: border 0.15s ease;
    width: 100%;
    color: $text-primary;
    background: $field-bg;
    @include border($field-border);
    text-align: center;

    &::placeholder {
      color: $field-placeholder;
    }

    &:focus {
      @include border($field-active-border);
    }

    &.error {
      border-color: $accent-danger;
    }
  }

  &__controls {
    width: 18px;
    height: 36px;
    position: absolute;

    &.right {
      right: 0;
    }
  }

  &__control {
    height: 18px !important;
    width: 18px !important;

    &.left {
      @include border-right;

      &:first-child {
        @include border-bottom;
        border-radius: 0;
        border-top-left-radius: 0.25rem;
      }

      &:last-child {
        border-radius: 0;
        border-bottom-left-radius: 0.25rem;
      }
    }

    &.right {
      &:first-child {
        @include border-bottom;
        border-radius: 0;
        border-top-right-radius: 0.25rem;
      }

      &:last-child {
        border-radius: 0;
        border-bottom-right-radius: 0.25rem;
      }
    }
  }

  &.disabled {
    .r-input-number__input {
      cursor: not-allowed;
      opacity: 0.4;
    }
  }

  &.mini {
    .r-input-number__controls {
      height: 28px;
      width: 14px;
    }
    .r-input-number__input {
      padding: 0 0.75rem;
      height: 28px;
    }

    .r-input-number__control {
      height: 14px !important;
      width: 14px !important;
    }
  }
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type='number'],
input[type='number']:hover,
input[type='number']:focus {
  appearance: none;
  -moz-appearance: textfield;
}
</style>
