export default {
  state: {
    city: '',
    map: {},
    filters: {
      orders: [],
      vehicles: []
    },
    tables: {},
    services: {
      statuses: null,
      vehicle_types: null
    },
    // Router
    routerCard: null,
    // Orders
    orderFields: null,
    orderCard: null,
    form_rules: null,
    // Vehicles
    showVehicles: false,
    vehiclesSidebar: false,
    vehicleCardId: null
  },
  getters: {
    getRoadWorkMapConfig: state => state.map,
    getRoadWorkSelectedCity: state => state.city,
    getRoadWorkStatusColorById: state => id =>
      state.services.statuses?.[id]?.color || '#ffffff'
  },
  mutations: {
    ROAD_WORKS_SET(state, [field, value]) {
      if (field.includes('.')) {
        const subField = field.split('.')

        state[subField[0]][subField[1]] = value
      } else {
        state[field] = value
      }
    }
  }
}
