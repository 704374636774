const DEV_MODE = process.env.NODE_ENV === 'development'
const ENV_HOST = process.env.VUE_APP_HOST

const URLS = {
  eputsiso: 'test2.ritm3.ru',
  test: 'test.ritm3.ru',
  demo: 'demo.ritm3.ru',
  asudd: 'asudd.ritm3.ru',
  new: 'new.ritm3.ru',
  surgut: 'surgut.ritm3.ru',
  prod: ''
}

const WS_PROTOCOLS = {
  'http:': 'ws:',
  'https:': 'wss:'
}

const { protocol, origin } = window.location

//  ----- * ----- * ----- * ----- * ----- * ----- * ----- * ----- * -----  //

let HOST
if (!DEV_MODE) {
  HOST = URLS.prod
} else {
  // Toggle URL below ↓

  HOST = URLS.eputsiso
  // HOST = URLS.asudd
  // HOST = URLS.surgut
}

//  ----- * ----- * ----- * ----- * ----- * ----- * ----- * ----- * -----  //

const URL = DEV_MODE ? `https://${HOST}` : URLS.prod
const ABS_URL = DEV_MODE ? `https://${HOST}` : `${protocol}//${ENV_HOST}`

//  ----- * ----- * ----- * ----- * ws * ----- * ----- * ----- * -----  //

const WSP = DEV_MODE ? WS_PROTOCOLS['https:'] : WS_PROTOCOLS[protocol]
const WSH = DEV_MODE ? HOST : ENV_HOST

const WS_URL = `${WSP}//${WSH}/ws`
const NOTIFY_WS_URL = `${WSP}//${WSH}/task_notification/ws`

//  ----- * ----- * ----- * ----- * ----- * ----- * ----- * ----- * -----  //
const MAIN_TITLE = 'RITM³'
export { NOTIFY_WS_URL, WS_URL, URL, MAIN_TITLE, ABS_URL }
