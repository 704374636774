export default {
  locale: 'ru',
  theme: 'dark',
  defaultPath: '/app/explorer',
  authorized: false,
  downloads: {},
  abbr: false,
  env: {},
  availableCities: [],
  defaultMapConfig: {
    baseLayer: 'dark',
    center: {
      lat: 61.25,
      lng: 73.397
    },
    zoom: 11,
    bearing: 0,
    pitch: 0
  },
  notification: null,
  storeRelations: {
    gis: 'GIS_SET',
    mciud: 'MCIUD_SET',
    asudd: 'ASUDD_SET',
    ksodd: 'KSODD_SET',
    parking: 'PARKING_SET',
    licenses: 'LICENSES_SET',
    modeling: 'MODELING_SET',
    roadWorks: 'ROAD_WORKS_SET',
    emergency: 'EMERGENCY_SET',
    itsControl: 'ITS_CONTROL_SET',
    digitalTwin: 'DIGITAL_TWIN_SET',
    permissions: 'PERMIT_REGISTER_SET',
    efficiency_control: 'ITS_CONTROL_SET',
    publicTransport: 'PUBLIC_TRANSPORT_SET',
    public_transport: 'PUBLIC_TRANSPORT_SET',
    pdd: 'PDD_SET'
  },
  additionalGisLayers: {
    modeling: [],
    public_transport: []
  },
  isDrawing: false,
  customBaseLayers: []
}
