<template>
  <el-date-picker
    class="r-datepicker"
    :label="label"
    :value="value"
    :type="type"
    :name="name"
    :align="align"
    :format="format"
    :picker-options="pickerOptions"
    :disabled="disabled"
    :clearable="clearable"
    :placeholder="placeholder"
    @input="inputHandler"
    @focus="$emit('focus')"
    @blur="$emit('blur')"
    @change="$emit('change')"
  />
</template>

<script>
export default {
  name: 'RDatePicker',
  props: {
    value: {
      type: [Date, Number, String],
      default: null
    },
    label: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'date'
    },
    name: {
      type: String,
      default: 'datetime'
    },
    align: {
      type: String,
      default: 'center'
    },
    format: {
      type: String,
      default: 'dd.MM.yyyy'
    },
    pickerOptions: {
      type: Object,
      default: () => null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ''
    },
    clearable: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {}
  },
  methods: {
    inputHandler(event) {
      this.$emit('input', event)
    }
  }
}
</script>

<style lang="scss">
.r-datepicker {
  .el-input__inner {
    color: var(--text-primary) !important;
    border-color: var(--field-border) !important;
    background-color: var(--field-bg) !important;
  }

  &.el-input--prefix {
    .el-input__inner {
      padding-left: 30px !important;
    }
  }
  &.el-input--suffix {
    .el-input__inner {
      padding-right: 30px !important;
    }
  }
  &.el-input--suffix,
  &.el-input--prefix {
    .el-input__icon {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &.is-disabled .el-input__inner {
    opacity: 0.4;
  }
}

// date picker dropdown panel
.el-picker-panel {
  background-color: var(--modal-bg) !important;
  border-color: var(--dividers-low-contrast) !important;
  color: var(--text-primary) !important;
  z-index: 3001 !important;

  &__icon-btn {
    color: var(--accent-primary) !important;
  }

  &__link-btn.is-plain {
    color: var(--white) !important;
    background-color: var(--accent-primary) !important;
    border-color: var(--accent-primary) !important;
  }

  .el-input__inner {
    color: var(--text-primary) !important;
    border-color: var(--field-border) !important;
    background-color: var(--field-bg) !important;
  }

  &__footer {
    background-color: var(--modal-bg) !important;
    border-color: var(--dividers-low-contrast) !important;

    .el-button {
      border-radius: $border-radius;
      padding: 0.25rem 0.75rem;
      border: none;
      color: var(--text-primary) !important;
      background-color: var(--accent-primary-1) !important;
      cursor: pointer;
      transition: background 0.15s ease;

      &:hover {
        background-color: var(--accent-primary-2) !important;
      }
      & + .el-button {
        margin-left: 0.5rem;
      }
    }
  }

  .el-date-picker {
    &__time-header {
      border-color: var(--dividers-low-contrast) !important;
    }

    &__header--bordered {
      border-color: var(--dividers-low-contrast) !important;
    }

    &__header-label {
      color: var(--accent-primary) !important;
    }
  }

  .el-date-table {
    td.next-month,
    .el-date-table td.prev-month {
      color: var(--text-secondary) !important;
    }

    th {
      color: var(--text-secondary) !important;
      border-color: var(--dividers-low-contrast) !important;
    }

    td.disabled div {
      background-color: var(--bg-panel-primary) !important;
    }
  }

  .el-time-panel {
    background-color: var(--modal-bg) !important;
    border-color: var(--dividers-low-contrast) !important;

    &__footer {
      border-color: var(--dividers-low-contrast) !important;
    }

    &__btn.cancel {
      color: var(--text-primary) !important;
    }

    &__content::after,
    &__content::before {
      border-color: var(--dividers-low-contrast) !important;
    }
  }

  .el-time-spinner {
    &__item {
      color: var(--text-secondary) !important;

      &.active {
        color: var(--text-primary) !important;
      }
    }

    &__item:hover:not(.disabled):not(.active) {
      background-color: var(--accent-primary-1) !important;
    }
  }

  .el-month-table td .cell,
  .el-year-table td .cell {
    color: var(--text-primary) !important;
  }

  .el-month-table td.current .cell {
    color: var(--accent-primary) !important;
  }

  .popper__arrow {
    display: none !important  ;
  }
}

.el-date-editor {
  .el-input__inner {
    height: 36px;
    color: var(--text-primary) !important;
    border-color: var(--field-border) !important;
    background-color: var(--field-bg) !important;
  }

  &.el-input--prefix {
    .el-input__inner {
      padding-left: 30px !important;
    }
  }
  &.el-input--suffix {
    .el-input__inner {
      padding-right: 30px !important;
    }
  }
  &.el-input--suffix,
  &.el-input--prefix {
    .el-input__icon {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &.is-disabled .el-input__inner {
    opacity: 0.4;
  }

  &.el-input {
    width: 100% !important;
  }
}
</style>
