<template>
  <label :class="`r-checkbox ${whiteSpace} ${disabled ? 'disabled' : ''}`">
    <input
      :id="id"
      :checked="isChecked"
      :disabled="disabled"
      class="r-checkbox__input"
      type="checkbox"
      @change="handleChange"
      @click="handleClick"
    />
    <checkbox-status
      :loading="loading"
      :state="state"
    />
    <r-text v-if="label">
      {{ label }}
    </r-text>
    <slot v-else />
  </label>
</template>

<script>
import CheckboxStatus from '@/components/r-ui/r-select/components/checkbox-status'

export default {
  components: { CheckboxStatus },
  model: {
    prop: 'checked',
    event: 'change'
  },
  props: {
    checked: {
      type: Boolean,
      default: false
    },
    indeterminate: {
      type: Boolean,
      default: false
    },
    id: {
      type: String,
      default: null
    },
    label: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    whiteSpace: {
      // nowrap, unset
      type: String,
      default: 'nowrap'
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isChecked: this.checked
    }
  },
  computed: {
    state() {
      if (this.indeterminate) return 'indeterminate'
      return this.isChecked ? 'checked' : 'unchecked'
    }
  },
  watch: {
    checked(value) {
      this.isChecked = value
    }
    // isChecked(value) {
    //   this.$emit('change', value)
    // }
  },
  methods: {
    handleChange() {
      if (this.disabled) return
      this.$emit('change', !this.isChecked)
    },
    handleClick(e) {
      if (this.disabled) return
      e.stopPropagation()
      this.$emit('click')
    }
  }
}
</script>

<style lang="scss" scoped>
.r-checkbox {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 0.5rem;
  cursor: pointer;
  align-items: center;

  &.disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }

  &__input {
    display: none;
  }
}

.nowrap .r-text {
  white-space: nowrap;
}
</style>
