var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"r-block",class:[
    {
      row: _vm.row,
      col: _vm.col,
      radius: _vm.radius,
      start: _vm.start,
      center: _vm.center,
      bottom: _vm.bottom,
      scroll: _vm.scroll,
      disabled: _vm.disabled,
      noPadding: _vm.noPadding,
      spaceBetween: _vm.spaceBetween,
      stretch: _vm.stretch,
      rtl: _vm.rtl,
      bottomSpace: _vm.bottomSpace
    },
    _vm.borderClass,
    _vm.bgClass
  ],style:(_vm.style)},[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }