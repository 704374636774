import rDate from 'ritm-date'
// import i18n from '@/extesions/i18n'

export const isNumber = n => {
  return !isNaN(parseFloat(n)) && isFinite(n)
}

export const typeIsNumber = type => {
  const types = {
    number: true,
    integer: true,
    numeric: true,
    decimal: true
  }
  return Object.hasOwn(types, type)
}

export const typeIsSimple = type => {
  return (
    typeIsNumber(type) || ['boolean', 'string'].includes(type.toLowerCase())
  )
}

export const dataURLtoBlob = dataurl => {
  const arr = dataurl.split(',')
  const mime = arr[0].match(/:(.*?);/)[1]
  const bstr = atob(arr[1])
  let n = bstr.length
  const u8arr = new Uint8Array(n)

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }

  return new Blob([u8arr], { type: mime })
}

export const saveBlobAsFile = (blob, name) => {
  const a = document.createElement('a')
  const url = URL.createObjectURL(blob)

  a.href = url
  a.download = name

  document.body.appendChild(a)
  a.click()

  setTimeout(function () {
    document.body.removeChild(a)
    window.URL.revokeObjectURL(url)
  }, 0)
}

export const capitalizeFirst = string => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export const getPolygonCoordsArrayByGeom = geom => {
  const isMultiGeom = geom?.type.toLowerCase().includes('multi')
  return isMultiGeom ? geom?.coordinates[0][0] : geom?.coordinates[0]
}

export const geojsonByGeom = geometry => {
  return {
    type: 'FeatureCollection',
    features: [
      {
        type: 'Feature',
        properties: {},
        geometry
      }
    ]
  }
}
// TODO: all map fns move to /map.js
export const featuresToGeojson = features => {
  return {
    type: 'FeatureCollection',
    features
  }
}

export const emptyGeojson = {
  type: 'FeatureCollection',
  features: []
}

export const getValueByType = (value, type = 'default', k, lvl = 0) => {
  const level = 1 + lvl
  if (level > 2) return '...'

  const isObject = obj => {
    return Object.prototype.toString.call(obj) === '[object Object]'
  }
  if (value === null || value === undefined) {
    return '— '
  } else if (Array.isArray(value)) {
    return value.length > 1
      ? value.map(v => getValueByType(v, type)).join(', ')
      : getValueByType(value[0], type) || '— '
  } else if (isObject(value)) {
    const objectKeys = Object.keys(value)
    if (!objectKeys.length) {
      return '— '
    }
    return objectKeys
      .map(key => {
        if (key === 'geom' || key === 'geometry') {
          return 'geometry object'
        }
        return `${k ? '∟ ' + key : key}: ${
          isObject(value[key]) && !!Object.keys(value[key]).length
            ? '\n' + getValueByType(value[key], type, key, level)
            : getValueByType(value[key], type) + ';'
        }`
      })
      .join('\n')
  } else if (typeof value === 'boolean') {
    // return i18n.t(value ? 'yes' : 'no') // TODO: upgrade to i18n
    return value ? 'Да' : 'Нет'
  } else if (type === 'date') {
    return rDate(value).format('DD.MM.YYYY')
  } else if (type === 'datetime') {
    return rDate(value).format('DD.MM.YYYY HH:mm')
  } else if (type === 'time') {
    if (rDate(value).date == 'Invalid Date') {
      return value.split('+')[0]
    } else {
      rDate(value).format('HH:mm')
    }
  } else {
    return value || isNumber(value) ? String(value) : '—'
  }
}
