<template>
  <label
    :class="{ checked: isChecked }"
    class="r-radio"
  >
    <input
      :id="id"
      ref="input"
      :checked="isChecked"
      class="r-radio__input"
      type="radio"
      @change="handleChange"
    />
    <div class="r-radio__status" />
    <r-text>
      <slot />
    </r-text>
  </label>
</template>

<script>
export default {
  name: 'RRadio',
  model: {
    prop: 'checked',
    event: 'change'
  },
  props: {
    id: {
      type: [String, Number],
      required: true
    },
    checked: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isChecked: false,
      text: ''
    }
  },
  watch: {
    checked(value) {
      this.isChecked = value
    }
  },
  mounted() {
    this.isChecked = this.checked
    this.text = this.$slots.default[0].text.trim()
  },
  methods: {
    handleChange() {
      this.$refs.input.checked = this.isChecked
      this.$emit('change', true)
    }
  }
}
</script>

<style lang="scss" scoped>
.r-radio {
  display: flex;
  gap: 12px;
  padding: 0.25rem 0;
  cursor: pointer;
  align-items: center;

  &__status {
    width: 20px;
    height: 20px;
    border: 2px solid $accent-primary;
    border-radius: 50%;
    position: relative;

    &::after {
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: transparent;
      position: absolute;
      top: calc(50% - 6px);
      left: calc(50% - 6px);
      content: '';
    }
  }

  &__input {
    display: none;
  }

  &.checked {
    .r-radio__status::after {
      background: $accent-primary;
    }
  }
}
</style>
