<template>
  <div>
    <portal to="main-portal">
      <transition-group
        class="notifications"
        name="list-complete"
        tag="div"
      >
        <notification
          v-for="item in list"
          :id="item.id"
          :key="item.id"
          class="list-complete-item"
          :message="item.message"
          :title="item.title"
          :duration="item.duration"
          :type="item.type"
          @delete="handleDelete"
        />
      </transition-group>
    </portal>
  </div>
</template>

<script>
export default {
  components: {
    Notification: () => import('./notification')
  },
  data() {
    return {
      list: [],
      parent: null
    }
  },
  methods: {
    addNotification({ type, title, message, duration }) {
      this.list.unshift({
        title,
        message: message || '',
        duration,
        type,
        id: (+new Date()).toString(16)
      })
    },
    handleDelete(id) {
      this.list = this.list.filter(item => item.id !== id)
    }
  }
}
</script>

<style lang="scss">
.notifications {
  position: fixed;
  top: 1rem;
  right: 1rem;
  z-index: 4000;
  display: grid;
  grid-gap: 1rem;
  align-items: start;
  align-content: flex-start;
  max-height: calc(100vh - 2rem);
}

.list-complete-item {
  transition: transform 0.33s, opacity 0.5s;
}

.list-complete-enter {
  opacity: 0;
  transform: translateX(100%);
}

.list-complete-leave-to {
  opacity: 0;
}

.list-complete-leave-active {
  transform: translateX(100%);
}
</style>
