<template>
  <div
    :class="['r-dropdown', isMenuShown ? 'opened' : '']"
    tabindex="-1"
  >
    <div
      ref="dropdown"
      class="r-dropdown__dropdown"
      @click="toggleShown"
    >
      <slot />
    </div>
    <portal to="main-portal">
      <transition name="unroll">
        <div
          v-if="isMenuShown"
          ref="menu"
          tabindex="0"
          class="r-dropdown__menu"
          :style="{
            width: width ? `${width}px` : 'auto',
            maxWidth: `${menuMaxWidth}px`,
            maxHeight: `${menuMaxHeight}px`,
            [stickTo]: `${left || right}`,
            top: `${top}px`,
            bottom: `${bottom}px`,
            minWidth: `${minWidth}px`
          }"
        >
          <r-title
            v-if="menuTitle"
            type="subtitle-2"
          >
            {{ menuTitle }}
          </r-title>

          <slot
            v-if="isMenuShown"
            name="dropdown-menu"
            class="r-dropdown__menu-list"
          />
        </div>
      </transition>
    </portal>
  </div>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: null
    },
    menuTitle: {
      type: String,
      default: ''
    },
    menuMaxWidth: {
      type: Number,
      default: null
    },
    menuMaxHeight: {
      type: Number,
      default: 250
    },
    width: {
      type: Number,
      default: null
    },
    stickTo: {
      type: String,
      default: 'left'
    }
  },
  data() {
    return {
      top: null,
      left: null,
      right: null,
      bottom: null,
      minWidth: null,
      menuOnTop: false,
      isMenuShown: false
    }
  },
  watch: {
    show(val) {
      if (val === false) {
        this.emitClose()
      }
    }
  },
  mounted() {
    this.updateMenuCoords()
    document.addEventListener('click', this.closeMenu, true)
    document.addEventListener('scroll', this.closeMenu, true)
    window.addEventListener('resize', this.closeMenu, true)
  },
  beforeDestroy() {
    document.removeEventListener('click', this.closeMenu, true)
    document.removeEventListener('scroll', this.closeMenu, true)
    window.removeEventListener('resize', this.closeMenu, true)
    if (this.show !== null) {
      this.emitClose()
    }
  },
  methods: {
    toggleShown() {
      this.updateMenuCoords()
      this.isMenuShown = !this.isMenuShown
    },
    closeMenu(e) {
      if (e.type === 'resize') {
        this.isMenuShown = false
        return
      }

      if (
        this.isMenuShown &&
        e.target.closest('.r-dropdown__dropdown') &&
        !e.target.closest('.r-dropdown')?.classList.contains('opened')
      ) {
        this.isMenuShown = false
      }

      if (e.target.closest('.r-dropdown__dropdown')) {
        return
      }
      if (
        !e.target.closest('.r-dropdown__menu') &&
        !e.target.closest('.r-dropdown__dropdown') &&
        !e.target.closest('.r-datepicker') &&
        !e.target.closest('.daterangepicker') &&
        !e.target.closest('.select-menu') &&
        !e.target.closest('.r-select-option') &&
        !e.target.closest('.el-date-picker')
      ) {
        this.isMenuShown = false
      }
    },
    updateMenuCoords() {
      if (!this.$refs.dropdown) return
      const rect = this.$refs.dropdown.getBoundingClientRect()
      const windowHeight = document.documentElement.clientHeight
      const menuOnTop = rect.bottom > windowHeight - this.menuMaxHeight
      this.minWidth = rect.width
      if (menuOnTop) {
        this.bottom = windowHeight - rect.top + 8
        this.top = null
      } else {
        this.top = rect.bottom + 8
        this.bottom = null
      }

      switch (this.stickTo) {
        case 'left':
          this.right = null
          this.left = `${rect.left}px`
          break
        default:
          this.left = null
          this.right = `calc(100vw - ${rect.right}px)`
      }
    },
    emitClose() {
      this.isMenuShown = false
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
.r-dropdown {
  width: fit-content;

  &__dropdown {
    display: flex;
    z-index: 100;
    width: fit-content;
  }

  &__menu {
    background: $modal-bg;
    position: absolute;
    box-shadow: $box-shadow-s-dark;
    border-radius: 4px;
    border: 1px solid $modal-overlay;
    min-width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    display: grid;
    width: max-content;
    z-index: 3000;
  }

  &__menu-list {
    padding: 0 0 0.5rem;
  }

  .r-title {
    padding: 0.5rem;
  }
}

.unroll-enter-active,
.unroll-leave-active {
  transition: opacity 0.1s ease;
  overflow: hidden;
  display: block;
  max-height: 250px;
  opacity: 1;
}

.unroll-enter,
.unroll-leave-to {
  max-height: 0;
  opacity: 0;
}
</style>
