import { cloneDeep } from 'HELPERS'

export default {
  state: {
    config: {
      city: '',
      timezone: ''
    },
    map: {},
    layers: [],
    layersOnMap: {},
    filters: {},
    aliases: {},
    dashboardFilters: {},
    dashboardActive: [],
    visiblePanels: {
      left: true,
      bottom: true
    }
  },
  getters: {
    getItsDataById: state => (id, field) =>
      state[field].find(item => item.id === id),
    getItsField: state => field => state[field]
  },
  mutations: {
    ITS_CONTROL_SET(state, [field, value]) {
      if (field.includes('.')) {
        const subField = field.split('.')
        if (state[subField[0]][subField[1]]) {
          state[subField[0]][subField[1]] = value
        } else {
          const updField = cloneDeep(state[subField[0]])
          updField[subField[1]] = value
          state[subField[0]] = updField
        }
      } else {
        state[field] = value
      }
    },
    ITS_CONTROL_CLEAR(state, field) {
      if (field.includes('.')) {
        const subField = field.split('.')
        delete state[subField[0]][subField[1]]
      } else {
        delete state[field]
      }
    },
    ITS_CONTROL_ADD(state, [field, value]) {
      state[field].push(value)
    },
    ITS_CONTROL_DELETE(state, [field, value]) {
      const index = state[field].indexOf(value)
      state[field].splice(index, 1)
    }
  }
}
