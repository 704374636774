import Vue from 'vue'
import VueRouter from 'vue-router'
import $store from 'STORE'
import { MAIN_TITLE } from '@/config/constants'
import i18n from '@/extesions/i18n'

Vue.use(VueRouter)

const wrapper = () => import('@/views/wrapper/wrapper')

const beforeEnter = (to, from, next) => {
  document.title = `${MAIN_TITLE} ${
    to.meta?.title ? `| ${i18n.t(to.meta.title)}` : ''
  }`

  if (!$store.getters.getToken && to.name !== 'login') {
    next({
      name: 'login'
    })
  } else if (from.name !== to.name) {
    next()
    if (to.name !== 'login') {
      $store.commit('SYSTEM_SET', ['defaultPath', to.path])
    }
  }
}

const routes = [
  {
    path: '/app',
    name: 'app',
    component: () => import('@/views/app/app'),
    beforeEnter,
    redirect: 'app/explorer',
    meta: {
      title: ''
    },
    children: [
      {
        // explorer
        name: 'explorer',
        path: 'explorer',
        beforeEnter,
        component: () => import('@/modules/explorer/explorer'),
        meta: {
          title: 'explorer'
        }
      },
      {
        // planning
        name: 'planning',
        path: 'planning',
        beforeEnter,
        component: wrapper,
        meta: {
          title: 'planning:title',
          icon: 'ksodd-map',
          description: 'planning:desc'
        },
        children: [
          {
            name: 'gis',
            path: 'gis',
            beforeEnter,
            component: () => import('@/modules/gis/gis'),
            meta: {
              title: 'gis:module-title',
              abbr: 'gis:abbr-module-title',
              icon: 'geometry-instruments',
              description: 'gis:module-desc',
              projects: [
                'eputs',
                'surgut',
                'mmk',
                'niipi',
                'rut_miit',
                'ryazan',
                'tagil',
                'minimal'
              ]
            }
          },
          {
            name: 'ksodd-podd',
            path: 'ksodd',
            beforeEnter,
            component: wrapper,
            meta: {
              title: 'ksodd:title',
              abbr: 'ksodd:abbr-title',
              icon: 'ksodd',
              description: 'ksodd:desc',
              projects: ['eputs', 'surgut']
            },
            children: [
              {
                name: 'ksodd',
                path: 'ksodd',
                beforeEnter,
                component: () => import('@/modules/ksodd/ksodd'),
                meta: {
                  title: 'ksodd:title',
                  abbr: 'ksodd:abbr-title',
                  icon: 'ksodd',
                  description: 'ksodd:desc'
                }
              },
              {
                name: 'podd',
                path: 'podd',
                beforeEnter,
                component: () => import('@/modules/ksodd/podd'),
                meta: {
                  title: 'ksodd:title',
                  subtitle: 'ksood:podd-subtitle',
                  abbr: 'ksodd:abbr-title',
                  icon: 'ksodd',
                  description: 'ksodd:desc'
                }
              }
            ]
          }
        ]
      },
      {
        // monitoring
        name: 'monitoring',
        path: 'monitoring',
        beforeEnter,
        component: wrapper,
        meta: {
          title: 'monitoring',
          icon: 'camera',
          description: 'monitoring:desc'
        },
        children: [
          {
            name: 'monitoring-pt',
            path: 'monitoring-pt',
            beforeEnter,
            redirect: 'monitoring-pt/main',
            component: wrapper,
            meta: {
              title: 'monitoring-pt:title',
              abbr: 'monitoring-pt:abbr-title',
              icon: 'bus-hollow',
              description: 'monitoring-pt:module-description',
              purpose: 'development',
              projects: ['eputs', 'tagil']
            },
            children: [
              {
                name: 'pt-main',
                path: 'main',
                meta: {
                  title: 'monitoring-pt:title',
                  abbr: 'monitoring-pt:abbr-title',
                  icon: 's-center',
                  description: 'monitoring-pt:desc'
                },
                beforeEnter,
                component: () =>
                  import('@/modules/monitoring-pt/views/monitoring-pt')
              },
              {
                name: 'pt-dashboard',
                path: 'dashboard',
                meta: {
                  title: 'monitoring-pt:title',
                  abbr: 'monitoring-pt:abbr-title',
                  subtitle: 'monitoring-pt:subtitle',
                  icon: 's-center',
                  description: 'monitoring-pt:desc'
                },
                beforeEnter,
                component: () =>
                  import('@/modules/monitoring-pt/views/dashboard')
              }
            ]
          },
          {
            name: 'its-control',
            path: 'its-control',
            beforeEnter,
            redirect: 'its-control/main',
            component: wrapper,
            meta: {
              title: 'its:title',
              abbr: 'its:abbr-title',
              icon: 's-center',
              description: 'its:desc',
              projects: ['eputs', 'surgut', 'mmk', 'rut_miit', 'tagil']
            },
            children: [
              {
                name: 'its-main',
                path: 'main',
                meta: {
                  title: 'its:title',
                  abbr: 'its:abbr-title',
                  icon: 's-center',
                  description: 'its:desc'
                },
                beforeEnter,
                component: () => import('@/modules/its-control/views/main')
              },
              {
                name: 'its-dashboard',
                path: 'dashboard',
                meta: {
                  title: 'its:title',
                  abbr: 'its:abbr-title',
                  subtitle: 'its:dashboard_title',
                  icon: 's-center',
                  description: 'its:desc'
                },
                beforeEnter,
                component: () => import('@/modules/its-control/views/dashboard')
              },
              {
                name: 'its-monitoring',
                path: 'monitoring',
                meta: {
                  title: 'its:title',
                  abbr: 'its:abbr-title',
                  subtitle: 'its:monitoring',
                  icon: 's-center',
                  description: 'its:desc'
                },
                beforeEnter,
                component: () =>
                  import('@/modules/its-control/views/monitoring')
              },
              {
                name: 'its-notifications',
                path: 'notifications',
                meta: {
                  title: 'its:title',
                  abbr: 'its:abbr-title',
                  subtitle: 'its:notifications',
                  icon: 's-center',
                  description: 'its:desc'
                },
                beforeEnter,
                component: () =>
                  import('@/modules/its-control/views/notifications')
              },
              {
                name: 'its-editor',
                path: 'editor',
                meta: {
                  title: 'its:title',
                  abbr: 'its:abbr-title',
                  subtitle: 'its:editor',
                  icon: 's-center',
                  description: 'its:desc'
                },
                beforeEnter,
                component: () => import('@/modules/its-control/views/editor')
              }
            ]
          },
          {
            name: 'licenses',
            path: 'licenses',
            beforeEnter,
            component: () => import('@/modules/licenses/licenses'),
            meta: {
              title: 'permissions:title',
              abbr: 'permissions:abbr-title',
              icon: 'bdd',
              description: 'permissions:desc',
              projects: ['eputs']
            }
          }
        ]
      },
      {
        // management
        name: 'management',
        path: 'management',
        beforeEnter,
        component: wrapper,
        meta: {
          title: 'management:title',
          icon: 'modelling-calculate',
          description: 'management:desc'
        },
        children: [
          {
            name: 'road_works',
            path: 'road_works',
            beforeEnter,
            component: wrapper,
            redirect: 'road_works/orders',
            meta: {
              title: 'road_works:title',
              abbr: 'road_works:abbr-title',
              icon: 'work',
              description: 'road_works:description',
              projects: ['surgut', 'eputs']
            },
            children: [
              {
                name: 'rw-orders',
                path: 'orders',
                component: () =>
                  import('@/modules/road-works/views/rw-main.vue'),
                meta: {
                  title: 'road_works:title',
                  abbr: 'road_works:abbr-title',
                  description: 'road_works:description'
                }
              },
              {
                name: 'rw-book',
                path: 'book',
                component: () =>
                  import('@/modules/road-works/views/rw-book.vue'),
                meta: {
                  title: 'road_works:title',
                  abbr: 'road_works:abbr-title',
                  description: 'road_works:description'
                }
              },
              {
                name: 'rw-routes',
                path: 'routes',
                component: () =>
                  import('@/modules/road-works/views/rw-routes.vue'),
                meta: {
                  title: 'road_works:title',
                  abbr: 'road_works:abbr-title',
                  description: 'road_works:description'
                }
              }
            ]
          },
          // {
          //   name: 'road-works',
          //   path: 'road-works',
          //   beforeEnter,
          //   component: () => import('@/modules/road-works-2'),
          //   meta: {
          //     title: 'road-works',
          //     abbr: 'abbr-road-works',
          //     icon: 'work',
          //     description: 'road-works:description',
          //     projects: ['eputs', 'surgut']
          //   }
          // },
          {
            name: 'asudd',
            path: 'asudd',
            beforeEnter,
            component: () => import('@/modules/asudd/asudd'),
            meta: {
              title: 'asudd:title',
              abbr: 'asudd:abbr-title',
              icon: 'traffic-light',
              description: 'asudd:desc',
              purpose: 'development',
              projects: ['eputs', 'ryazan']
            }
          },
          {
            name: 'parking',
            path: 'parking',
            beforeEnter,
            component: wrapper,
            redirect: 'parking/main',
            meta: {
              title: 'parking:title',
              abbr: 'parking:abbr-title',
              icon: 'parking',
              description: 'parking:desc',
              projects: ['eputs']
            },
            children: [
              {
                name: 'parking-main',
                path: 'main',
                beforeEnter,
                component: () => import('@/modules/parking/views/parking'),
                meta: {
                  title: 'parking:title',
                  icon: 'parking',
                  description: 'parking:desc'
                }
              },
              {
                name: 'dashboard',
                path: 'dashboard',
                beforeEnter,
                component: () => import('@/modules/parking/views/dashboard'),
                meta: {
                  title: 'parking:edit-title',
                  description: 'parking:edit-desc'
                }
              }
            ]
          },
          {
            name: 'pdd',
            path: 'pdd',
            beforeEnter,
            component: wrapper,
            redirect: 'pdd/main',
            meta: {
              title: 'pdd:title',
              abbr: 'pdd:abbr-title',
              icon: 'camera',
              description: 'pdd:desc',
              projects: ['eputs']
            },
            children: [
              {
                name: 'pdd-main',
                path: 'main',
                beforeEnter,
                component: () => import('@/modules/pdd/views/pdd'),
                meta: {
                  title: 'pdd:title',
                  icon: 'camera',
                  description: 'pdd:desc'
                }
              },
              {
                name: 'pdd-registry',
                path: 'registry',
                beforeEnter,
                component: () => import('@/modules/pdd/views/registry'),
                meta: {
                  title: 'pdd:registry-title',
                  description: 'pdd:registry-desc'
                }
              }
            ]
          },
          {
            name: 'emergency',
            path: 'emergency',
            beforeEnter,
            component: wrapper,
            redirect: 'emergency/main',
            meta: {
              title: 'emergency:title',
              abbr: 'emergency:abbr-title',
              icon: 'tune',
              description: 'emergency:desc',
              projects: ['eputs', 'surgut']
            },
            children: [
              {
                name: 'emergency-main',
                path: 'main',
                beforeEnter,
                component: () => import('@/modules/emergency/views/emergency'),
                meta: {
                  title: 'emergency:title',
                  description: 'emergency:desc'
                }
              }
            ]
          },
          {
            name: 'mciud',
            path: 'mciud',
            beforeEnter,
            component: () => import('@/modules/mciud/mciud'),
            meta: {
              title: 'mciud:title',
              abbr: 'mciud:abbr-title',
              icon: 'info-screen',
              description: 'mciud:desc',
              purpose: 'development',
              projects: ['eputs']
            }
          }
        ]
      },
      {
        name: 'forecast',
        path: 'forecast',
        beforeEnter,
        component: wrapper,
        meta: {
          title: 'forecast:title',
          icon: 'tune-component',
          description: 'forecast:desc'
        },
        children: [
          {
            name: 'digital-twin',
            path: 'digital-twin',
            beforeEnter,
            component: wrapper,
            redirect: 'digital-twin/dt-selector',
            meta: {
              title: 'digital-twin:title',
              abbr: 'digital-twin:abbr-title',
              icon: 'edit-zone',
              description: 'digital-twin:desc',
              projects: ['niipi', 'eputs']
            },
            children: [
              {
                name: 'dt-selector',
                path: 'dt-selector',
                beforeEnter,
                component: () =>
                  import('@/modules/digital-twin/views/dt-selector'),
                meta: {
                  title: 'digital-twin:title',
                  subtitle: 'digital-twin:select-model',
                  abbr: 'digital-twin:abbr-title',
                  icon: 'edit-zone',
                  description: 'digital-twin:desc'
                }
              },
              {
                name: 'dt-main',
                path: 'dt-main',
                beforeEnter,
                component: () => import('@/modules/digital-twin/views/dt-main'),
                meta: {
                  title: 'digital-twin:title',
                  icon: 'edit-zone',
                  description: 'digital-twin:desc'
                }
              }
            ]
          },
          {
            name: 'modeling',
            path: 'modeling',
            beforeEnter,
            component: () => import('@/modules/modeling/views/wrapper'),
            redirect: 'modeling/selector',
            meta: {
              title: 'modeling:title',
              abbr: 'modeling:abbr-title',
              icon: 'modelling',
              description: 'modeling:desc',
              projects: ['niipi', 'eputs']
            },
            children: [
              {
                name: 'selector',
                path: 'selector',
                beforeEnter,
                component: () =>
                  import(
                    '@/modules/modeling/views/model-selector/model-selector'
                  ),
                meta: {
                  title: 'modeling:title',
                  subtitle: 'modeling:selector-subtitle',
                  abbr: 'modeling:abbr-title',
                  icon: 'modelling',
                  description: 'modeling:desc'
                }
              },
              {
                name: 'view',
                path: 'view',
                beforeEnter,
                component: () =>
                  import('@/modules/modeling/views/model-view/model-view'),
                meta: {
                  title: 'modeling:title',
                  subtitle: 'modeling:view-subtitle',
                  abbr: 'modeling:abbr-title',
                  icon: 'modelling',
                  description: 'modeling:desc'
                }
              },
              {
                name: 'matrices',
                path: 'matrices',
                beforeEnter,
                component: () =>
                  import('@/modules/modeling/views/matrices/matrices'),
                meta: {
                  title: 'modeling:title',
                  subtitle: 'modeling:matrices-subtitle',
                  abbr: 'modeling:abbr-title',
                  icon: 'modelling',
                  description: 'modeling:desc'
                }
              },
              {
                name: 'procedures',
                path: 'procedures',
                beforeEnter,
                component: () =>
                  import('@/modules/modeling/views/procedures/procedures'),
                meta: {
                  title: 'modeling:title',
                  subtitle: 'modeling:procedures-subtitle',
                  abbr: 'modeling:abbr-title',
                  icon: 'modelling',
                  description: 'modeling:desc'
                }
              },
              {
                name: 'book',
                path: 'book',
                beforeEnter,
                component: () => import('@/modules/modeling/views/book/book'),
                meta: {
                  title: 'modeling:title',
                  subtitle: 'modeling:book-subtitle',
                  abbr: 'modeling:abbr-title',
                  icon: 'modelling',
                  description: 'modeling:desc'
                }
              }
            ]
          }
        ]
      },
      {
        // system
        name: 'system',
        path: 'system',
        beforeEnter,
        component: wrapper,
        meta: {
          title: 'system',
          icon: 'settings',
          description: 'system:desc'
        },
        children: [
          {
            name: 'mrir',
            path: 'mrir',
            beforeEnter,
            component: () => import('@/modules/mrir/mrir'),
            meta: {
              title: 'mrir:title',
              abbr: 'mrir:abbr-title',
              icon: 'users',
              description: 'mrir:desc',
              projects: ['eputs', 'surgut', 'niipi', 'rut_miit']
            }
          },
          {
            name: 'profile',
            path: 'profile',
            beforeEnter,
            component: () => import('@/modules/user/profile/profile'),
            meta: {
              title: 'user:profile_title',
              abbr: 'user:abbr-profile_title',
              icon: 'user',
              // description: 'user:profile_desc',
              purpose: 'development',
              projects: ['eputs']
            }
          }
          // {
          //   name: 'ui',
          //   path: 'ui',
          //   beforeEnter,
          //   component: () => import('@/modules/ui-kit/ui-kit'),
          //   meta: {
          //     title: 'UI компоненты',
          //     abbr: 'UI',
          //     icon: 'style-presets',
          //     purpose: 'development',
          //     projects: []
          //   }
          // }
        ]
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/modules/user/auth/login'),
    beforeEnter,
    meta: {
      title: 'Авторизация'
    }
  },
  { name: 'all-routes', path: '*', redirect: $store.getters.getDefaultPath }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// Hack
const originalPush = router.push
router.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalPush.call(this, location, onResolve, onReject)
  }

  return originalPush.call(this, location).catch(err => {
    if (VueRouter.isNavigationFailure(err)) {
      return err
    }

    return Promise.reject(err)
  })
}

export default router
