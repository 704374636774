<template>
  <router-view />
</template>

<script>
import { toggleBodyTheme } from 'HELPERS'

export default {
  created() {
    this.$store.commit('SYSTEM_SET', ['env', Object.assign({}, process.env)])
    toggleBodyTheme(this.$store.getters.getTheme)
  },
  beforeCreate() {
    this.$store.commit('SYSTEM_SET', ['notification', null])
  }
}
</script>
