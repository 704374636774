import axios from 'axios'
import $store from 'STORE'
import $router from '@/router'
import { URL } from '@/config/constants'

import eventBus, { eventNames } from 'EVENT_BUS'

const { LICENSE_ERROR } = eventNames

const getHeaders = props => {
  return {
    ...props,
    headers: {
      Authorization: `Bearer ${$store.getters.getToken}`
    }
  }
}

const errorHandler = e => {
  const status = e.response?.status
  const code = e.code
  switch (status) {
    case 401:
    case 422:
      $store.commit('CLEAR_USER_DATA')
      $router.push({ path: '/login' })
      break
    case 403:
      eventBus.emit(LICENSE_ERROR)

      $store.commit('SYSTEM_SET', [
        'notification',
        {
          type: 'warning',
          title: `У Вас нет доступа к этим данным`,
          message: 'Обратитесь к администратору'
        }
      ])
      break
    default:
      $store.commit('SYSTEM_SET', [
        'notification',
        {
          type: 'danger',
          title: `Ошибка ${status} | ${code}`,
          message: 'Обратитесь к администратору'
        }
      ])
      break
  }
}

export default {
  GET(url) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${URL}/${url.replaceAll('+', '%2B')}`, getHeaders())
        .then(data => resolve(data))
        .catch(error => {
          errorHandler(error)
          reject(error)
        })
    })
  },
  GET_BLOB(url) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${URL}/${url}`, getHeaders({ responseType: 'blob' }))
        .then(data => resolve(data))
        .catch(error => {
          errorHandler(error)
          reject(error)
        })
    })
  },
  PUT(url, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${URL}/${url}`, data, getHeaders())
        .then(data => resolve(data))
        .catch(error => {
          errorHandler(error)
          reject(error)
        })
    })
  },
  POST(url, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${URL}/${url}`, data, getHeaders())
        .then(data => resolve(data))
        .catch(error => {
          errorHandler(error)
          reject(error)
        })
    })
  },
  POST_UPLOAD(url, data, name) {
    const tempId = (+new Date()).toString(16)

    return new Promise((resolve, reject) => {
      axios
        .post(`${URL}/${url}`, data, {
          ...getHeaders(),
          onUploadProgress: e => {
            const progress = parseInt(Math.round((e.loaded / e.total) * 100))

            $store.commit('SYSTEM_ASSIGN', {
              field: 'downloads',
              key: tempId,
              value: progress >= 99 ? null : { name, progress }
            })
          }
        })
        .then(data => resolve(data))
        .catch(error => {
          errorHandler(error)
          reject(error)
        })
    })
  },
  DELETE(url) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${URL}/${url}`, getHeaders())
        .then(data => resolve(data))
        .catch(error => {
          errorHandler(error)
          reject(error)
        })
    })
  }
}
