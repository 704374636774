export default {
  state: {
    activeModule: 'users',

    endpointsList: null,

    roles: null,

    activeRoleId: '',
    updateRoles: false,
    activeRolePerms: null,
    initialActiveRolePerms: null,

    activeUserId: '',
    updateUsers: false,
    activeUserData: null,
    initialActiveUserData: null,
    activeUserRolePerms: null,
    initialActiveUserRolePerms: null,
    activeUserPerms: null,
    initialActiveUserPerms: null
  },
  mutations: {
    MRIR_SET(state, [field, value]) {
      if (field.includes('.')) {
        const subField = field.split('.')

        state[subField[0]][subField[1]] = value
      } else {
        state[field] = value
      }
    }
  }
}
