import Vue from 'vue'
import Vuex from 'vuex'
import actions from './actions'
import getters from './getters'
import mutations from './mutations'
import state from './state'

// Modules
import gis from '@/modules/gis/config/store'
import mrir from '@/modules/mrir/config/store'
import user from '@/modules/user/config/store'
import ksodd from '@/modules/ksodd/config/store'
import itsControl from '@/modules/its-control/config/store'
import roadWorks from '@/modules/road-works/config/store'
import emergency from '@/modules/emergency/config/store'
import publicTransport from '@/modules/monitoring-pt/config/store'
import asudd from '@/modules/asudd/config/store'
import parking from '@/modules/parking/config/store'
import modeling from '@/modules/modeling/config/store'
import mciud from '@/modules/mciud/config/store'
import licenses from '@/modules/licenses/config/store'
import digitalTwin from '@/modules/digital-twin/config/store'
import pdd from '@/modules/pdd/config/store'

// import roadWorks2 from '@/modules/road-works-2/config/store'

Vue.use(Vuex)

export default new Vuex.Store({
  state,
  getters,
  mutations,
  actions,
  strict: process.env.NODE_ENV !== 'production',
  modules: {
    user,
    gis,
    ksodd,
    mrir,
    asudd,
    parking,
    mciud,
    modeling,
    roadWorks,
    emergency,
    itsControl,
    digitalTwin,
    publicTransport,
    licenses,
    pdd

    // roadWorks2,
  }
})
