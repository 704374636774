import Vue from 'vue'

export default {
  state: {
    city: '',
    tree: [],
    map: {
      // baselayer: 'dark',
      // zoom: 16,
      // center: { lat: 61.26, lng: 73.41 },
      // pitch: 0,
      // bearing: 0
    },
    layerStyles: {},
    activeLayers: [],
    fields: {},
    isSelectDisabled: false,
    hash: {},
    layerNames: {}
  },
  getters: {
    getKsoddMapConfig: state => state.map,
    getKsoddActiveLayers: state => state.activeLayers,
    getKsoddFiltersById: state => id => state.layerStyles[id]?.filters || [],
    getKsoddLayerStyles: state => state.layerStyles,
    getKsoddSelectedCity: state => state.city
  },
  mutations: {
    KSODD_SET(state, [field, value]) {
      if (field.includes('.')) {
        const subField = field.split('.')
        Vue.set(state[subField[0]], subField[1], value)
      } else {
        Vue.set(state, field, value)
      }
    },
    KSODD_SET_FILTER_PROP(state, { filterId, action, layerId, prop, value }) {
      const { filters } = state.layerStyles[layerId]
      const index = filters.findIndex(({ id }) => id === filterId)
      if (action === 'delete') {
        filters.splice(index, 1)
      } else if (!filters[index]) {
        console.warn('filter not found!')
        return
      } else if (prop && value !== undefined) {
        filters[index][prop] = value
      }
    },
    KSODD_PUSH(state, [field, value]) {
      state[field].push(value)
    },
    KSODD_SPLICE(state, [field, value]) {
      const index = state[field].indexOf(value)
      if (index >= 0) state[field].splice(index, 1)
    }
  }
}
