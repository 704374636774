export default {
  getAdditionalGisLayersByModule: state => module =>
    state.additionalGisLayers[module],
  getAdditionalGisLayers: state => state.additionalGisLayers,
  getMapConfigByModule: state => module => state[module].map,
  getCustomBaseLayers: state => state.customBaseLayers,
  getMapPosition: state => state.user_config?.position,
  getGeomEditorState: state => state.isDrawing,
  getProfileId: state => state.user?.profile?.id,
  getMapZoom: state => state.user_config?.zoom,
  getDefaultPath: state => state.defaultPath,
  isAuthorized: state => state.authorized,
  getDownloads: state => state.downloads,
  getLocale: state => state.locale,
  getTheme: state => state.theme,
  getAbbr: state => state.abbr
}
