export default {
  state: {
    city: '',
    map: {},
    busStopId: null,
    busStopProperties: null,
    busRouteId: null,
    vehicleId: null,
    activeRoutesId: [],
    activeGroupsId: [],
    currentRoute: null,
    currentRoutes: [],
    currentPointCoordinates: [],
    routeColor: '#F37843',
    fields: null,
    boardsId: null
  },
  getters: {
    getPTBusStopId: state => state.busStopId,
    getPTBusRouteId: state => state.busRouteId,
    getPTBusStopProperties: state => state.busStopProperties,
    getPTactiveRoutesId: state => state.activeRoutesId,
    getPTactiveGroupsId: state => state.activeGroupsId,
    getPTvehicleId: state => state.vehicleId,
    getPTcity: state => state.city,
    getPTcurrentRoutes: state => state.currentRoutes,
    getCurrentPointCoordinates: state => state.currentPointCoordinates,
    getPTrouteColor: state => state.routeColor,
    getPTfields: state => state.fields,
    getPTBoardsId: state => state.boardsId
  },
  mutations: {
    PUBLIC_TRANSPORT_SET(state, [field, value]) {
      if (field.includes('.')) {
        const subField = field.split('.')

        state[subField[0]][subField[1]] = value
      } else {
        state[field] = value
      }
    },
    PUBLIC_TRANSPORT_PUSH(state, [field, value]) {
      state[field].push(value)
    },
    PUBLIC_TRANSPORT_SPLICE(state, [field, value]) {
      const index = state[field].indexOf(value)
      if (index >= 0) state[field].splice(index, 1)
    }
  }
}
