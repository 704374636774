import vue from 'vue'

export default {
  state: {
    map: {},
    city: 'ekb',
    violationId: null,
    cameraId: null,
    fields: {},
    statuses: []
  },
  getters: {
    getPDDMapConfig: state => state.map,
    getPDDCity: state => state.city,
    getPDDViolationId: state => state.violationId,
    getPDDCameraId: state => state.cameraId,
    getPDDFields: state => state.fields,
    getPDDStatuses: state => state.statuses
  },
  mutations: {
    PDD_SET(state, [field, value]) {
      if (field.includes('.')) {
        const [parent, child] = field.split('.')
        vue.set(state[parent], `${child}`, value)
      } else {
        vue.set(state, `${field}`, value)
      }
    }
  }
}
