var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ul',{ref:"list",class:['select-menu', `${_vm.multiple ? 'multiple' : ''}`, _vm.mode],style:({
    top: `${_vm.top}px`,
    bottom: `${_vm.bottom}px`,
    left: `${_vm.left}px`,
    width: `${_vm.width}px`
  })},[(_vm.filteredOptions.length)?[(_vm.multiple && !_vm.hasNoTextData)?_c('r-select-option',{class:[
        'select-menu__menu-item',
        `${_vm.focusedElementId === 'select_all' ? 'focus' : ''}`
      ],attrs:{"id":"select_all"},on:{"click":function($event){return _vm.$emit('select-all')}}},[_c('checkbox-status',{attrs:{"state":_vm.selectedStatus}}),_c('r-text',[_vm._v(" "+_vm._s(_vm.$t('select_all'))+" ")])],1):_vm._e(),_vm._l((_vm.filteredOptions),function(option){return _c('r-select-option',{key:option.id,attrs:{"id":option.id,"disabled":option.disabled,"title":option.title || option.name,"html":option.html},on:{"click":function($event){$event.stopPropagation();return _vm.itemClickHandler(option.id, $event)}}})})]:_c('div',{staticClass:"select-menu__no-data"},[_c('r-text',[_vm._v(" "+_vm._s(_vm.$t('no-data'))+" ")])],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }