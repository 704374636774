var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'r-color-item',
    { active: _vm.active, wide: _vm.mode === 'wide', transparent: _vm.mode === 'add' }
  ],on:{"click":function($event){return _vm.$emit(_vm.mode || 'click')}}},[(_vm.mode === 'add')?_c('r-icon',{attrs:{"name":"add-plus"}}):_c('div',{staticClass:"r-color-item__canvas"},[_c('div',{staticClass:"r-color-item__color",style:({
        backgroundColor: _vm.color,
        opacity: _vm.opacity
      })},[(_vm.mode === 'remove')?_c('r-icon',{staticClass:"r-color-item__close-icon",attrs:{"name":"close-delete"}}):_vm._e()],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }