import Vue from 'vue'
import { getCookie, deleteCookie } from '../helpers/cookies'

const setSourceIdById = (hash, array) => {
  array.forEach(({ id, source_id, children }) => {
    hash[id] = source_id

    if (children && children.length) setSourceIdById(hash, children)
  })
}

export default {
  state: {
    token: getCookie('ritmToken'),
    user: null,
    profile: null,
    // profiles: [],
    permissions: {
      role: null,
      user: null
    }
  },
  getters: {
    getToken: state => state.token,
    getProfile: state => state.profile,
    getUserId: state => state.user?.id,
    // getProfiles: state => state.profiles,
    hasModule: state => module =>
      !!state.modules.find(({ name }) => name === module),
    hasInstrument: state => (module, instrument) => {
      const foundModule = state.modules.find(({ name }) => name === module)
      return !foundModule
        ? false
        : !!foundModule.instruments.find(({ name }) => name === instrument)
    }
  },
  mutations: {
    USER_SET(state, [field, value]) {
      if (field.includes('.')) {
        const subField = field.split('.')
        Vue.set(state[subField[0]], subField[1], value)
      } else {
        Vue.set(state, field, value)
        state[field] = value
      }
    },
    CLEAR_USER_DATA(state) {
      state.datasources = {}
      state.user_config = {
        datasources: {},
        objectInfoFields: {},
        baselayerId: 5
      }

      state.token = undefined
      state.user = undefined
      deleteCookie('ritmToken')
    }
  }
}
