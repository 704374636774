<!-- eslint-disable vue/no-unused-vars -->
<template>
  <div
    ref="wrapper"
    class="r-datepicker-wrapper"
  >
    <r-text v-if="label"> {{ label }}</r-text>
    <date-range-picker
      ref="picker"
      :class="['r-datepicker', { error }]"
      :min-date="minDate"
      :max-date="maxDate"
      :date-range="pickerType === 'range' ? rangeDate : singleDate"
      :readonly="disabled"
      :locale-data="localeData"
      :single-date-picker="pickerType"
      :auto-apply="type === 'date' || pickerType === 'range'"
      :ranges="showRanges ? ranges() : false"
      :append-to-body="true"
      :calculate-position="calcPos"
      :show-dropdowns="showDropdowns"
      opens="left"
      :date-format="dateFormat"
      :time-picker="type === 'datetime' && pickerType === 'single'"
      @update="inputHandler"
      @toggle="toggle"
    >
      <template #input>
        <r-icon
          name="date-calendar"
          class="r-datepicker__icon"
        />
        <r-text
          v-if="
            pickerType === 'range'
              ? !rangeDate.startDate
              : !singleDate.startDate
          "
          color-type="secondary"
        >
          {{ placeholder }}
        </r-text>
        <r-text v-else>
          {{ inputValue }}
        </r-text>
      </template>
      <div
        slot="footer"
        slot-scope="data"
        class="r-datepicker__footer"
      >
        <r-button
          v-if="!data.in_selection && type === 'datetime'"
          type="success"
          mini
          @click="data.clickApply"
        >
          {{ $t('apply') }}
        </r-button>
      </div>
    </date-range-picker>
    <r-button
      v-if="clearable"
      class="r-datepicker__clear"
      tabindex="-1"
      simple
      icon="clear-input"
      mini
      @click.prevent.stop="clear"
    />
  </div>
</template>

<script>
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

export default {
  name: 'RDatePicker',
  components: { DateRangePicker },
  props: {
    value: {
      type: [Date, Array, String],
      default: null
    },
    label: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      validator: type => ['date', 'datetime'].includes(type),
      default: 'date'
    },
    pickerOptions: {
      type: Object,
      default: () => null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ''
    },
    clearable: {
      type: Boolean,
      default: false
    },
    showRanges: {
      type: Boolean,
      default: false
    },
    pickerType: {
      type: String,
      validator: type => ['single', 'range'].includes(type),
      default: 'single'
    },
    minDate: {
      type: [Date, Number, String],
      default: null
    },
    maxDate: {
      type: [Date, Number, String],
      default: null
    },
    showDropdowns: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      rangeDate: {
        startDate: null,
        endDate: null
      },
      singleDate: { startDate: null, endDate: null },
      dateFormated: null,
      isOpenedCalendar: false
    }
  },
  computed: {
    // TODO: make locales reactive from arrays
    localeData() {
      const daysOfWeek = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun']
      const monthNames = [
        'jan',
        'feb',
        'mar',
        'apr',
        'may',
        'jun',
        'jul',
        'aug',
        'sep',
        'oct',
        'nov',
        'dec'
      ]

      return {
        direction: 'ltr',
        format: this.actualFormat.toLowerCase(),
        separator: ' ',
        applyLabel: 'Apply',
        cancelLabel: 'Cancel',
        weekLabel: 'W',
        customRangeLabel: 'Custom Range',
        daysOfWeek: daysOfWeek.map(e => this.$t(`${e}`)),
        monthNames: monthNames.map(e => this.$t(`${e}`)),
        firstDay: 1
      }
    },
    inputValue() {
      let startDate
      if (this.pickerType === 'range') {
        startDate = this.rangeDate.startDate
          ? this.$rDate(this.rangeDate?.startDate).format(this.actualFormat)
          : null
      } else {
        startDate = this.singleDate?.startDate
          ? this.$rDate(this.singleDate?.startDate).format(this.actualFormat)
          : null
      }

      const endDate = this.rangeDate?.endDate
        ? this.$rDate(this.rangeDate.endDate).format(this.actualFormat)
        : null

      return this.pickerType === 'range'
        ? `${startDate} — ${endDate}`
        : startDate
    },
    actualFormat() {
      if (this.type === 'date') {
        return 'DD.MM.YYYY'
      } else {
        return 'DD.MM.YY • HH:mm'
      }
    }
  },
  watch: {
    value: {
      handler(val) {
        if (this.pickerType === 'single') {
          this.singleDate = { startDate: val, endDate: val }
        } else {
          this.rangeDate = {
            startDate: val[0],
            endDate: val[1]
          }
        }
      },
      immediate: true
    }
  },
  mounted() {
    document.addEventListener('scroll', this.closeMenu, true)
  },
  beforeDestroy() {
    document.removeEventListener('scroll', this.closeMenu, true)
  },
  methods: {
    toggle(event) {
      this.isOpenedCalendar = event
    },
    inputHandler(event) {
      if (this.pickerType === 'single') {
        this.dateFormated = event.startDate
        this.$emit('input', this.dateFormated)
      } else {
        this.dateFormated = [event.startDate, event.endDate]
        this.$emit('input', this.dateFormated)
      }
    },
    calcPos(dropdownList) {
      const coordsPicker = this.$refs.picker.$el.getBoundingClientRect()
      const windowHeight = document.documentElement.clientHeight
      const menuOnTop = coordsPicker.bottom > windowHeight - 330

      dropdownList.style.position = 'absolute'

      if (menuOnTop) {
        dropdownList.style.bottom = windowHeight - coordsPicker.top + 8 + 'px'
      } else {
        dropdownList.style.top = coordsPicker.bottom + 8 + 'px'
      }

      dropdownList.style.left = Math.floor(coordsPicker.left) + 'px'

      if (this.showRanges) {
        dropdownList.style.width = '390px'
      } else {
        dropdownList.style.width = '280px'
      }
    },
    dateFormat(classes, date) {
      if (!classes.disabled && this.pickerOptions) {
        classes.disabled = this.pickerOptions.disabledDate(date)
      }
      return classes
    },
    closeMenu() {
      if (this.isOpenedCalendar) {
        this.$refs.picker?.togglePicker(false)
        this.isOpenedCalendar = false
      }
    },
    clear() {
      this.rangeDate = {
        startDate: null,
        endDate: null
      }
      this.singleDate = {
        startDate: null,
        endDate: null
      }
    },
    ranges() {
      if (this.showRanges) {
        const today = new Date()
        today.setHours(0, 0, 0, 0)

        const yesterday = new Date()
        yesterday.setDate(today.getDate() - 1)
        yesterday.setHours(0, 0, 0, 0)
        const thisMonthStart = new Date(
          today.getFullYear(),
          today.getMonth(),
          1
        )
        const thisMonthEnd = new Date(
          today.getFullYear(),
          today.getMonth() + 1,
          0,
          11,
          59,
          59,
          999
        )

        return {
          [this.$t('today')]: [today, today],
          [this.$t('yesterday')]: [yesterday, yesterday],
          [this.$t('this-month')]: [thisMonthStart, thisMonthEnd],
          [this.$t('this-year')]: [
            new Date(today.getFullYear(), 0, 1),
            new Date(today.getFullYear(), 11, 31)
          ],
          [this.$t('last-month')]: [
            new Date(today.getFullYear(), today.getMonth() - 1, 1),
            new Date(today.getFullYear(), today.getMonth(), 0)
          ]
        }
      } else {
        return false
      }
    }
  }
}
</script>

<style lang="scss">
.daterangepicker.opensleft:after,
.daterangepicker.opensleft:before {
  display: none;
}
.r-datepicker {
  &__placeholder {
    position: absolute;
    top: 2.25rem;
    left: 2.5rem;
    pointer-events: none;
  }

  &-wrapper {
    position: relative;
    display: grid;
    gap: 8px;
  }

  &__footer {
    background-color: $modal-bg;
    display: flex;
    justify-content: flex-end;
    padding: 0 1rem 0.5rem 0;
  }

  .reportrange-text {
    height: 36px;
    padding: 0 1rem 0 0.5rem;
    display: flex;
    gap: 0.25rem;
    align-items: center;
    border-radius: 4px;
    color: $text-primary !important;
    border-color: $field-border !important;
    background-color: $field-bg !important;
    border: 1px solid $field-border;
  }

  &__clear {
    position: absolute;
    right: 4px;
    bottom: 4px;
    background: transparent;
    border: none;
    z-index: 1;
    opacity: 0;
    height: 22px;

    &:hover {
      opacity: 1;
      cursor: pointer;
    }
  }

  &:hover ~ .r-datepicker__clear {
    opacity: 1;
    cursor: pointer;
  }

  &.error {
    .reportrange-text {
      border-color: $accent-danger !important;
    }
  }

  // &__svg {
  //   width: 22px;
  //   height: 22px;
  //   fill: $icons-high-contrast;

  //   &:hover {
  //     fill: $accent-primary;
  //   }
  // }

  // &__icon {
  //   fill: $icons-high-contrast;
  //   // position: absolute;
  //   // left: 8px;
  //   // bottom: 8px;
  // }
}

.daterangepicker {
  border: 1px solid $field-border !important;
  background-color: $field-border;
  .calendars {
    border-radius: 4px;
    background-color: $modal-bg !important;
    border: none;
    color: $text-primary !important;
    z-index: 3001 !important;
  }

  .calendar-table {
    border: none !important;
  }

  .calendar-table,
  .monthselect,
  .yearselect,
  td {
    background-color: $modal-bg !important;
  }

  th {
    background-color: $modal-bg !important;
    color: $text-secondary;
  }

  .next span,
  .prev span {
    border-color: $accent-primary !important;
  }

  .monthselect,
  .yearselect {
    color: $accent-primary !important;
  }

  td {
    width: 42px !important;
    height: 38px !important;

    &:hover {
      color: $accent-primary !important;
    }

    &.in-range {
      color: $white !important;
      background: $accent-primary-1 !important;

      &.active {
        color: $white !important;
        background: $accent-primary !important;
      }
    }
  }

  .drp-calendar {
    border: none !important;
  }

  .calendar-time {
    color: $text-primary;

    .form-control {
      background: $modal-bg !important;
      color: $text-primary;
      border-color: $field-border;
    }
  }

  .drp-buttons {
    background: $modal-bg !important;
    border-color: $field-border;

    .cancelBtn {
      border-radius: 4px;
      color: $text-primary;
      background-color: $button-secondary-bg;
      cursor: pointer;

      &:hover {
        background-color: $button-secondary-bg-hover;
      }
    }

    .applyBtn {
      border-radius: 4px;
      color: $text-primary;
      background-color: $accent-success;
      cursor: pointer;

      &:hover {
        background-color: $accent-success-hover;
      }
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .ranges li {
    border-radius: 4px;

    &.active {
      background-color: $accent-primary;
      color: $text-primary;
    }

    &:hover {
      background-color: transparent;
      color: $accent-primary;
    }
  }
}
</style>
